import React, { useState, useRef } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

function FileUploadForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        file: null,
    });

    const [errors, setErrors] = useState({});
    const fileInputRef = useRef(null);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value,
        });
    };

    const validateForm = () => {
        const newErrors = {};

        // Name validation (Required, should be more than 3 characters)
        if (!formData.name || formData.name.length < 3) {
            newErrors.name = 'Name is required and should be at least 3 characters long.';
        }

        // Email validation (Required, basic email format)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email || !emailPattern.test(formData.email)) {
            newErrors.email = 'A valid email is required.';
        }

        // Phone validation (Required, must be a 10-digit number)
        if (!formData.phone) {
            newErrors.phone = 'Phone number is required.';
        }

        // File validation (Required, only allow .pdf and .docx)

        if (!formData.file) {
            newErrors.file = 'File upload is required.';
        } else if (!['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'].includes(formData.file.type)) {
            newErrors.file = 'Only .pdf, .docx, and .doc file formats are allowed.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form before submitting
        if (!validateForm()) {
            return;
        }

        const data = new FormData();
        data.append('name', formData.name);
        data.append('email', formData.email);
        data.append('phone', formData.phone);
        data.append('file', formData.file);

        try {
            const response = await axios.post('https://apipreinterview.vidmagin.com/api/upload', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Upload successful:', response.data);
            toast.success('Resume Upload Successfully');
        } catch (error) {
            console.error('Upload failed:', error.response?.data || error.message);
            toast.error('Upload failed. Please try again.');
        }

        setFormData({
            name: '',
            email: '',
            phone: '',
            file: null,
        });

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <div className="flex flex-col items-center py-8">
                <h1 className="text-2xl font-bold mb-6 text-red-500">Fill Your Details</h1>
                <div className="max-w-md w-full p-6 bg-gray-100 rounded-lg shadow-md">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-gray-700 font-semibold mb-1">Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder='Enter Name'
                                required
                                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            {errors.name && <div className="text-red-500 text-sm mt-1">{errors.name}</div>}
                        </div>

                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 font-semibold mb-1">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder='Enter mail'
                                required
                                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            {errors.email && <div className="text-red-500 text-sm mt-1">{errors.email}</div>}
                        </div>

                        <div className="mb-4">
                            <label htmlFor="phone" className="block text-gray-700 font-semibold mb-1">Phone Number:</label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder='Enter Mobile Number'
                                required
                                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            {errors.phone && <div className="text-red-500 text-sm mt-1">{errors.phone}</div>}
                        </div>

                        <div className="mb-4">
                            <label htmlFor="file" className="block text-gray-700 font-semibold mb-1">File:</label>
                            <input
                                type="file"
                                id="file"
                                name="file"
                                onChange={handleChange}
                                ref={fileInputRef}
                                required
                                className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            {errors.file && <div className="text-red-500 text-sm mt-1">{errors.file}</div>}
                        </div>

                        <button
                            type="submit"
                            className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>



        </>
    );
}

export default FileUploadForm;
