import FileUploadForm from './Pages/FileUploadForm';
import './index.css';

function App() {
  return (
    <>
    <FileUploadForm/>
    </>
  );
}

export default App;
